import  {TYPICAL_STATUS} from '../../../misc/commons'
import DistributionModel from '../distribution-list/DistributionModel'
import providerService from '../../../services/provider/distribution-list'
import supplierService from '../../../services/supplier/distribution-list'
import store from '../../../store'
export default {
    components: {
        DistributionModel
    },
    data() {
        return {
            total: 0,
            status: 'ALL',
            pageNumber: 1,
            pageSize: 1000,
            items: [],
            searchQuery: {},
            header: [
                {
                    label: 'Name',
                    name: 'name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Recipients',
                    name: 'recipients',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: false,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right'
                }
            ],
            typical_status: TYPICAL_STATUS,
            distributionModelKey: 0,
            orgType: store.state.userType,
            service: store.state.userType =='SUPPLIER' ? supplierService: providerService,
        }
    },
    methods: {
        onCompleted() {
            this.fetchData()
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },

        addMoreClick() {
            this.distributionModel += 1
            this.$nextTick(()=>{
                this.$refs.distributionModel.forceRerenderInsert()
            })
        },
        fetchData() {
            this.service.findDistributionList({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
            }).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },

        edit(item,e){
            e.stopPropagation()
            this.distributionModel += 1
            this.$nextTick(()=>{
                this.$refs.distributionModel.forceRerenderUpdate(item.id)
            })

        },
        view(item){
            this.distributionModel += 1
            this.$nextTick(()=>{
                this.$refs.distributionModel.forceRerenderView(item.id)
            })
        },
        changeStatus(item, e) {
            this.service.changeStatus(item.id).then(resp => {
                if (!resp.error) {
                    if (item.status=='ACTIVE' ) {
                        this._showToast('Distribution list deactivated successfully!')
                    } else {
                        this._showToast('Distribution list Activated successfully!')
                    }
                    this.fetchData()
                }
            })
            e.stopPropagation()
        }


    }
}