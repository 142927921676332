import VueTagsInput from '@johmun/vue-tags-input'
import providerService from '../../../../services/provider/distribution-list'
import supplierService from '../../../../services/supplier/distribution-list'
import recallService from '../../../../services/provider/recall'
import workflowService from '../../../../services/work-flow'
import store from '../../../../store'
export default {
    components: {
        VueTagsInput
    },
    data() {
        return {
            title: '',
            formType: '',
            tag: '',
            value: [],
            valueTag: [],
            emailError: '',
            domainError: '',
            autocompleteItems: [],
            item: {
                id: null,
                name: null,
                emails: [],
                comment: '',
            },
            orgType: store.state.userType,
            service: store.state.userType == 'SUPPLIER' ? supplierService : providerService,
        }
    },
    created() {
        this.getEmails()
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text?.toLowerCase().indexOf(this.tag?.toLowerCase()) !== -1
            })
        },
    },
    methods: {
        forceRerenderInsert() {
            this.item = {}
            this.valueTag = []
            this.value = []
            this.emailError = ''
            this.domainError = ''
            this.title = 'New Distribution List'
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id) {
            this.item = {}
            this.valueTag = []
            this.value = []
            this.title = 'Update Distribution List'
            this.formType = 'UPDATE'
            this.getDistributionList(id)

        },
        forceRerenderView(id) {
            this.item = {}
            this.valueTag = []
            this.value = []
            this.title = 'View Distribution List'
            this.formType = 'View'
            this.getDistributionList(id)
        },
        getEmails() {
            if (this.orgType == 'SUPPLIER') {
                workflowService.getDelegateEmailList(this.$store.state.orgId).then(resp => {
                    if (!resp.error) {
                        resp.data.d.forEach((val) => {
                            if (val) {
                                this.autocompleteItems.push({ text: val.email })
                            }
                        })
                    }
                })
            }
            else {
                workflowService.getDelegateEmailList(this.$store.state.orgId).then(resp => {
                    if (!resp.error) {
                        resp.data.d.forEach((val) => {
                            if (val) {
                                this.autocompleteItems.push({ text: val.email })
                            }
                        })
                    }
                })
            }
        },
        getDistributionList(id) {
            this.service.getDistributionList(id).then(resp => {
                if (!resp.error) {
                    this.item = resp.data.d
                    this.valueTag = []
                    this.value = []
                    this.item.emails.forEach(x => {
                        this.value.push(x)
                        this.valueTag.push({ text: x })
                    })
                    this.item.invalidemails.forEach(x => {
                        this.value.push(x)
                        this.valueTag.push({ text: x })
                    })
                    this.$refs.modal.show()
                }
            })
        },
        addCheck(val) {
            if (val.length > 0) {
                this.value = []
                val.forEach(x => {

                    this.checkEmailValidation(x.text)
                    if (!this.emailError) {
                        this.value.push(x.text)
                        this.checkDomainValidation(x.text)
                    }
                })
                if (this.value.length > 1) {
                    var valueOriginal = this.value.length
                    var valcurrent = val.length - 1
                    const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                    var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                    if (b) {
                        var i = 0
                        for (i = 0; i < valueOriginal - 1; i++) {
                            if (this.value[i].trim() === val[valcurrent].text.trim()) {
                                this.setvalidation()
                                this.emailError = 'The email has been already used.'
                            }
                        }

                    }
                }
            }
            else {
                this.removevalidation()
                this.value = []
                this.domainError = ''
            }
        },

        checkDomainValidation(email) {
            if (this.orgType == 'SUPPLIER') {
                recallService.getSupplierDamain(email, this.$store.state.orgId).then(resp => {
                    if (resp.error) {
                        this.setvalidation()
                        return false
                    }
                    else {
                        return true
                    }
                })
            }
            else {
                recallService.getDamain(email, this.$store.state.orgId).then(resp => {
                    if (resp.error) {
                        this.setvalidation()
                        return false
                    }
                    else {
                        return true
                    }
                })
            }

        },
        checkEmailValidation(email) {
            if (!email) {
                this.setvalidation()
                this.emailError = 'Email is required'
            } else { this.emailError = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                    this.setvalidation()
                }
            }
        },
        setvalidation() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:#dc3545 !important'
        },
        removevalidation() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = ' '
        },

        cancel() {
            this.$emit('onCompleted')
            this.$refs.modal.hide()
        },
        save() {
            this.removevalidation()
            if (this.domainError == 'domainError') {
                this.setvalidation()
                return this._showToast('The email domain is not valid.', { title: 'Error', variant: 'danger' })

            }

            var isValid = this.$refs['observer'].validate()
            if (isValid) {
                //Add duplicate email validation
                if (this.value.length > 1) {
                    for (let i = 0; i < this.value.length; i++) {
                        for (var k = i + 1; k < this.value.length; k++) {
                            if (this.value[i].trim() === this.value[k].trim()) {
                                this.setvalidation()
                                return this.emailError = 'The email has been already used.'
                            }

                        }

                    }

                }
                //end

                if (this.value.length == 0) {
                    this.setvalidation()
                    return this.emailError = 'Email is required'
                } else { this.emailError = '' }
                this.item.emails = this.value

                this.service.saveDistributionList(this.item).then(resp => {
                    if (!resp.error) {
                        if (resp.data.d == 'err_name_exist') {
                            this._showToast('Distribution list name already exists.', { title: 'Error', variant: 'danger' })
                            this.$refs.observer.setErrors({ 'name': ['Name is already exists.'] })
                        } else {
                            if (this.item.id > 0) {
                                this._showToast('Distribution list updated successfully!')
                            } else {
                                this._showToast('Distribution list created successfully!')
                            }
                            this.$refs.modal.hide()
                            this.$emit('onCompleted')
                        }
                    } else {
                        if (resp.data.c == 'err_name_exist') {
                            this.$refs.observer.setErrors({ 'name': ['Name is already exists.'] })
                        }
                        if (resp.data.c == 'err_invalid_email') {
                            this.setvalidation()
                        }
                    }
                })
            }
        },
    }

}